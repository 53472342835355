


import {Component, Prop, Vue} from 'vue-property-decorator';
import {Action, Getter, Mutation} from 'vuex-class';
import InfiniteLoading from 'vue-infinite-loading';
import IconSquareShare from '@/_modules/icons/components/icon-square-share.vue';
import IconSquareEdit from '@/_modules/icons/components/icon-square-edit.vue';
import IconSquareDelete from '@/_modules/icons/components/icon-square-delete.vue';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
import {TranslateResult} from 'vue-i18n';
import QrPopup from '@/_modules/promo/components/qr-popup/qr-popup.vue';

import chooseCompanyCategories from '@/views/components/popups/chooseCompanyCategories.vue';
import _cloneDeep from 'lodash.clonedeep';
import {TPromoPage} from '@/_types/promo-page/promo-page.type';
import promoPageApi from '@/_modules/promo/api/promo-page.api';
import {TEventTag} from '@/_types/event-tag.type';

const QR_CODE_URL_DOMAIN_PREFIX = 'https://' + window.location.host;

@Component({
  components: {
    IconSquareShare,
    IconSquareEdit,
    IconSquareDelete,
    SimplePopup,
    InfiniteLoading,
    QrPopup,
    chooseCompanyCategories
  },
})

export default class ExponentsList extends Vue {
  @Action('promoPageStore/getPromoPagesWithCodes') getPromoPagesWithCodes: any;
  @Action('promoPageStore/deletePromoPage') deletePromoPage: any;
  @Mutation('promoPageStore/setPromoPagesWithCodes') setPromoPagesWithCodes: any;
  @Getter('promoPageStore/getListAllCodes') getListAllCodes: any;
  @Getter('promoPageStore/isListAllMore') isListAllMore: boolean;
  @Getter('_eventStore/eventTags') getEventTags: TEventTag[];

  public externalId: string = null;
  public codeId: string = null;
  public isDeleteConfirmationVisible: boolean = false;
  public isQRCodePopupVisible: boolean = false;
  // public isEventTagsPopupVisible: boolean = false;
  public qrPopupTitle: TranslateResult = '';
  public qrPopupDescription: TranslateResult = '';
  public qrPopupContents: string = '';
  public tagsExternalId: string = '';
  public search: string = '';
  public qrExternalId: string = ''
  public promoPageSortingValue: number = 0
  public ListAllCodes: TPromoPage[] = [];

  public promoPageTagIds: number[] = [];
  public promoPageTags: TEventTag[] = [];

  public showCategoryChooser: boolean = false;

  public sortingValues: number[] = [];

  public urlPromoPage(externalId: string): string {
    if (!externalId) {
      return '';
    }
    return QR_CODE_URL_DOMAIN_PREFIX + this.$router.resolve({
      name: 'promo-page-events-company',
      params: {
        eventId: this.eventId.toFixed(0),
        external_id: externalId
      }
    }).href;
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get eventTags(): TEventTag[] {
    return (this.getEventTags && this.getEventTags.length) ? this.getEventTags : null;
  }

  public get sortedListAllCodes(): TPromoPage[] {
    this.ListAllCodes = _cloneDeep(this.getListAllCodes);
    return this.ListAllCodes.sort((a: TPromoPage, b: TPromoPage) => {
      if (b.sorting === '0' || b.sorting === 0 || !b.sorting) {
        return -1;
      } else if (a.sorting === '0' || a.sorting === 0 || !a.sorting) {
        return 1;
      } else if (a.sorting < b.sorting) {
        return -1;
      } else if (a.sorting > b.sorting) {
        return 1;
      }
      return -1;
    });
  }

  public created(): void {
    this.getPromoPagesWithCodes();
  }

  public async mounted(): Promise<void> {
    await this.$store.dispatch('_eventStore/getEventTags', {eventId: this.eventId});
  }

  @Prop({type: Boolean, default: false})
  public readonly isEventTagsPopupVisible: boolean;

  async onInfiniteHandler(state: any): Promise<void> {
    await this.getPromoPagesWithCodes();
    state.loaded();
  }

  private async deleteCompany(externalId: string, codeId: string): Promise<void> {
    this.externalId = externalId;
    this.isDeleteConfirmationVisible = true;
    this.codeId = codeId;
  }

  private async confirmedDelete(isConfirmed: boolean): Promise<void> {
    if (isConfirmed && this.externalId) {
      this.deletePromoPage({
        eventId: this.$route.params.eventId,
        externalId: this.externalId,
      });
      this.isDeleteConfirmationVisible = false;
      this.externalId = null;
    } else {
      this.isDeleteConfirmationVisible = false;
      this.externalId = null;
    }
  }

  private showQRPopup(): void {
    this.isQRCodePopupVisible = true;
  }

  private hideQRPopup(): void {
    this.isQRCodePopupVisible = false;
  }

  private showQrCompany(externalId: string): void {
    this.hideQRPopup();
    this.showQRPopup();
    this.setQRPopupContents(this.$t('sideBarTabs.contact.cards.company.qrTitle'), this.$t('sideBarTabs.contact.cards.company.qrDescr'), this.urlPromoPage(externalId));
  }

  private setQRPopupContents(title: TranslateResult = '', descr: TranslateResult = '', strToEncode: string = ''): void {
    if (!strToEncode) {
      return;
    }
    this.qrPopupTitle = title;
    this.qrPopupDescription = descr;
    this.qrPopupContents = strToEncode;
  }

  public async getTargetPromopage(): Promise<void> {
    this.promoPageTagIds = [];
    this.promoPageTags = [];
    const response = await promoPageApi.getPromoPage({eventId: this.eventId, externalId: this.tagsExternalId});

    if (response.data.tags) {
      this.promoPageTags = response.data.tags;
      this.promoPageTagIds = response.data.tags.map((x: TEventTag) => x.id);
    }
  }

  private async toggleCategoryChooser(promoPage: TPromoPage): Promise<void> {
    if (promoPage && promoPage.external_id) {
      this.tagsExternalId = promoPage.external_id;
      await this.getTargetPromopage();
      this.showCategoryChooser = !this.showCategoryChooser;
    }
  }

  public async displayTags(selectedTags: TEventTag[]): Promise<void> {

    if(selectedTags.length) {
      const payload = {
        event_id: this.$route.params.eventId,
        external_id: this.tagsExternalId,
        tags: selectedTags.map((x) => x.id),
      };

      await this.$store.dispatch('promoStore/updatePromoPage', payload);
    } else {
      const payload = {
        event_id: this.$route.params.eventId,
        external_id: this.tagsExternalId,
      };

      await this.$store.dispatch('promoStore/updatePromoPage', payload);
    }

    this.promoPageTagIds = [];
    this.promoPageTags = [];
  }

  public async setSortingValue(promoPage: TPromoPage, event: Event): Promise<void> {
    const response = await promoPageApi.getPromoPage({eventId: this.eventId, externalId: promoPage.external_id});

    let priority = parseInt((event.target as HTMLFormElement).value, 10) || 0;

    priority = Math.abs(priority);

    this.ListAllCodes.find((item, index) => {
      if (item.external_id === promoPage.external_id) {
        this.ListAllCodes[index].sorting = priority;
      }
      return item.external_id === promoPage.external_id;
    });

    const payload = {
      event_id: this.$route.params.eventId,
      external_id: promoPage.external_id,
      sorting: priority,
      tags: response.data.tags ? response.data.tags.map((x: TEventTag) => x.id) : []
    };

    await this.$store.dispatch('promoStore/updatePromoPage', payload);
    await this.setPromoPagesWithCodes({promoPage: promoPage, sortingValue: priority});
  }

}
