


import { Vue, Component } from 'vue-property-decorator';

import ExponentsList from '@/_modules/promo-cabinet/components/cabinet-exponents/exponents-list/exponents-list.vue';
import CreateExponents from '@/_modules/promo-cabinet/components/cabinet-exponents/create-exponents/create-exponents.vue';
import IconSearch from '@/_modules/icons/components/icon-search.vue';
import CreateTagsPopup from '@/_modules/promo/components/create-tags-popup/create-tags-popup.vue';
import HelpCrunchService from '@/_services/help-crunch.service';
import {Action, Getter, Mutation} from 'vuex-class';

@Component({
  components: {
    ExponentsList,
    CreateExponents,
    IconSearch,
    CreateTagsPopup,
  },
})

export default class CabinetExponents extends Vue {
  @Mutation('promoPageStore/SET_SEARCH') SET_SEARCH: any;
  // @Action('promoPageStore/getPromoPageCodes') getPromoPageCodes: any;
  @Getter('locationStore/getCountryList') getCountryList: any;
  @Action('promoPageStore/getPromoPagesWithCodes') getPromoPagesWithCodes: any;

  public isEventTagsPopupVisible: boolean = false
  public search: string = '';

  private showTagPopup(): void {
    this.isEventTagsPopupVisible = true;
  }

  private hideTagPopup(): void {
    this.isEventTagsPopupVisible = false;
  }

  public mounted(): void {
    this.SET_SEARCH({value: '', countryId: null});
  }

  public beforeDestroy(): void {
    this.SET_SEARCH({value: '', countryId: null});
  }

  public onSearch(): void {
    const trimmed = this.search.trim();
    let countryId: number = null;

    this.getCountryList.find((country: { name: string; id: number}) => {
      if (country.name.toLowerCase() === trimmed.toLowerCase()) {
        countryId = country.id;
      }
    });

    this.SET_SEARCH({value: this.search, countryId: countryId});
    this.getPromoPagesWithCodes();
  }
  private openSupportChat(event: Event): void {
    if (event && event.target) {
      const clickedElement: HTMLElement = event.target as HTMLElement;
      if (clickedElement.classList.contains('link')) {
        const helpCrunchInstance = HelpCrunchService._helpCrunch;
        if (!helpCrunchInstance) { // TODO: also check for instance.isInitialized and test it
          window.setTimeout(() => { this.openSupportChat(event); }, 1000);
          return;
        }
        helpCrunchInstance('openChat');
      }
    }
  }
}
