


import { Component, Vue, Watch } from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import { Validations } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';

import { TCodeList, TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TVuelidateRuleSet } from '@/_types/vuelitation-rule-set.type';
import ValidationHelper from '@/_helpers/validation.helper';
const duplicateError = 'ERROR: duplicate key value violates unique constraint "promopages_pk" (SQLSTATE 23505)';

type TCreateExponent = {
  title: string;
  code: string;
  external_id: string;
}

@Component
export default class CreateExponents extends Vue {
  @Action('promoPageStore/createPromoPage') createPromoPage: (promoPageData: any) => Promise<TPromoPage>;
  @Getter('promoPageStore/getListAllCodes') getListAllCodes: any;
  public duplicateExternalId: boolean = false;

  @Validations()
  public readonly validations: TVuelidateRuleSet<TCreateExponent> = {
    formData: {
      title: {
        required,
      },
      external_id: {
        required,
        isValidExternalId(externalId: string): boolean {
          return ValidationHelper.isValidExternalId(externalId);
        },
        uniqueExternalId(externalId: string): boolean {
          return !this.findNotUnique(externalId);
        }
      },
    },
  }

  public findNotUnique(externalId: string): TCodeList {
    return this.getListAllCodes.find((id: TCodeList) => {
      return id.external_id === externalId || this.duplicateExternalId;
    });
  }

  public promoPageCodeList: TCodeList[];
  public formData: TCreateExponent = {
    title: '',
    code: '',
    external_id: '',
  };

  @Watch('formData.title')
  private onFormDataTitleChange(): void {
    if(!this.formData.title) {
      this.formData.external_id = '';
    }
  }

  @Watch('formData.external_id')
  private onFormDataExternalIdChange(): void {
    this.formData.code = '';
  }

  private static randomChars(): string {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 3; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  private async postPromoPage(): Promise<void> {
    this.duplicateExternalId = false;
    this.generateAccessKey();

    if (this.$v.formData.$pending || this.$v.formData.$invalid || !this.formData.code) {
      return;
    }
    this.$v.$reset();
    const result = await this.createPromoPage({
      eventId: this.$route.params.eventId,
      promoPageData: { ...this.formData }
    });
    if( (result as any).error === duplicateError) {
      this.duplicateExternalId = true;
      this.$v.formData.external_id.$touch();
      return;
    }
    this.formData.title = '';
    this.formData.external_id = '';
    this.formData.code = '';
  }

  private generateAccessKey(): void {
    this.$v.formData.$touch();

    if (this.$v.formData.$pending || this.$v.formData.$invalid) {
      return;
    }

    this.formData.code = `${(~~(Math.random() * 1e8)).toString(19)}${CreateExponents.randomChars()}`;

  }
}
